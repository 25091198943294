@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@include mat.core();

$lab-partner-theme: mat.define-light-theme((typography: mat.define-typography-config($font-family: "Roboto, 'Helvetica Neue', sans-serif",
        $button: mat.define-typography-level(14px, 2, 500, $letter-spacing: normal),
      ),
    ));

@include mat.all-component-themes($lab-partner-theme);

:root {
  --notification-light-red: #ff9b96;
  --success-green: #5cb85c;
  --warn-orange: #f99157;
  --light-grey: #8f9cb5;
  --lighter-grey: #b6bece;
  --error-red: rgba(211, 27, 27);
  --mdc-dialog-supporting-text-color: black;
}

@import 'primeng';

// BEGIN New Angular Material overrides
.mat-mdc-tooltip {
  margin: 0.5rem;
}

.mat-mdc-tooltip-panel .mdc-tooltip .mdc-tooltip__surface {
  font-size: 0.75rem;
  padding: 0.5rem;
  letter-spacing: normal;
}

.mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: 1rem !important;
}

.mdc-button__label {
  display: flex;
  align-items: center;
}

.mat-mdc-tab-header>.mat-mdc-tab-label-container>.mat-mdc-tab-list>.mat-mdc-tab-labels>.mdc-tab.mat-mdc-tab {
  flex-grow: 0;
  padding: 0 1.5rem;
  min-width: 10rem;
}

.mat-mdc-tab-labels .mat-mdc-focus-indicator:not(.mdc-tab--active) {
  border-bottom: 1px solid lightgrey;
  margin-left: 1px;
  margin-right: 1px;
}

// Disable CDK overlay on a previously focused button, but still allow keyboard navigation highlighting
.cdk-program-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}

// END Angular Material overrides

// The next removes the default margin and padding from the body and html elements
// and ensures the app takes up the full height of the viewport
html,
body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.controls-container {
  width: 100%;
  padding: 5%;
}

.controls-container>* {
  width: 100%;
}

.add-bottom-padding {
  padding-bottom: 10px;
}

.button-row button {
  margin: 10px;
}

/* notification */
snack-bar-container.success {
  background-color: #5cb85c;
  color: #fff;
}

snack-bar-container.warn {
  background-color: #f99157;
  color: #fff;
}

snack-bar-container.error {
  background-color: rgba(211, 27, 27);
  color: #fff;
}

snack-bar-container.notification simple-snack-bar {
  font-size: 18px !important;
  //font-weight: bold;
}

.hide {
  display: none;
}

/* mat-table */
mat-footer-row mat-footer-cell {
  justify-content: center;
  font-style: italic;
}

.mat-tab-label-content {
  caret-color: transparent;
}

/* filter controls */
.search-div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 10px;
}

.search-form-field {
  width: 30%;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.search-form-field div.mat-form-field-underline {
  display: none;
}

.search-form-field div.mat-form-field-infix {
  border-top: 0px;
}

.search-form-field div.mat-form-field-wrapper {
  padding-bottom: 0px;
}

.search-form-field div.mat-form-field-suffix button {
  height: 26px;
  width: 26px;
  margin: 0px;
}

/* for dialog pop-up */
.btn-dialog-close {
  width: 45px;
  min-width: 0px !important;
  height: 40px;
  padding: 0px !important;
}

.search-div label {
  margin-left: 20px;
  margin-right: 10px;
}

.search-div button {
  margin: 10px;
}

.release-notes-dialog-container .mat-dialog-container,
.audit-dialog-container .mat-dialog-container,
.add-view-notes-dialog .mat-dialog-container,
.savanna-dialog-container .mat-dialog-container,
.add-column-dialog-container .mat-dialog-container {
  border-radius: 0.25em 0.25em 0.4em 0.4em;
  padding: 0px;
}

.replicates-dialog-content {
  height: fit-content;
  max-height: 70vh !important;
  overflow: hidden !important;
}

/* mat-confirm-dialog (customised dialog) */
.confirm-dialog-container-pre-wrap,
.confirm-dialog-container {
  .mat-dialog-container {
    border-radius: 0.25em 0.25em 0.4em 0.4em;
    padding: 0px;
  }

  .content-container {
    margin: 5px 5px 15px 5px;
    color: #8f9cb5;
    display: flex;
  }

  #close-icon {
    position: absolute;
    right: 0.4rem;
    order: 2;
    font-weight: bolder;
  }

  #close-icon:hover {
    cursor: pointer;
  }

  #no-button {
    height: 50px;
    width: 50%;
    background-color: #fc7169;
    color: white;
    border-radius: 0px;
  }

  #ok-button {
    height: 50px;
    width: 100%;
    background-color: #fc7169;
    color: white;
    border-radius: 0px;
  }

  #yes-button {
    height: 50px;
    width: 50%;
    background-color: #b6bece;
    color: white;
    border-radius: 0px;
  }

  span.content-span {
    padding: 35px 16px;
    text-align: center;
    font-size: 20px;
  }
}

.confirm-dialog-container-pre-wrap {
  span {
    white-space: pre-wrap;
  }
}

.audit-count-icon {
  cursor: pointer;

  &.disabled {
    color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;
  }
}

.scan-tooltip {
  white-space: pre-line;
}

.mat-drawer-container {
  background-color: unset !important;
}

.mat-drawer-content {
  display: unset !important;
}

.table-container {
  flex: 1 1 auto;
  overflow: auto;
}

mat-paginator {
  margin-top: 2px;
}

.dynamic-dialog-backdrop {
  background: rgba(0, 0, 0, 0.75);
}