// Component scaling basis
html {
  font-size: 14px;
}

// Component default typography
.p-component,
.p-inputtext {
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

.p-toast-icon {
  display: none;
}

.p-ripple.ripple-grey .p-ink {
  background: var(--light-grey);
}

.search-div {
  .p-inputtext {
    width: 30rem;
    border: 0;
    background-color: rgb(245, 245, 245);
  }
}

.input-end-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.p-button-default {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25rem;
  color: black;
  background-color: white;
  border-color: transparent;

  &:enabled:hover {
    color: black;
    background: rgba(108, 117, 125, 0.04);
    border-color: transparent;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:enabled:active {
    color: black;
    background: rgba(108, 117, 125, 0.16);
    border-color: transparent;
  }
}

.p-radiobutton-checked .p-radiobutton-box.p-highlight {
  border-color: initial;
  background: initial;
  color: #007bff;

  &:hover {
    border-color: initial !important;
    background: initial !important;
    color: #007bff !important;
  }

  .p-radiobutton-icon {
    background-color: #007bff;
  }
}

.p-default-form-field {
  display: flex;
  flex-flow: column;
  width: 100%;

  &.p-float-label:not(.p-static-float) label {
    margin-top: -1.25rem;
  }

  &.p-float-label:not(.p-static-float) input:focus ~ label,
  &.p-float-label:not(.p-static-float) input.p-filled ~ label {
    margin-top: 0;
  }

  small.p-error {
    position: absolute;
    left: 0.75rem;
    bottom: 0;
    animation: fadein 0.5s linear forwards;
  }

  &.bottom-border-only > .p-inputtext {
    border: 0;
    border-bottom: 1px solid lightgrey;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:enabled:focus {
      box-shadow: none;
    }
  }
}

.progress-bar-container {
  display: inline-flex;
  flex-flow: column;
  justify-content: space-evenly;
  width: 250px;
  height: 36px;
  vertical-align: middle;
  margin: 0 1rem;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
